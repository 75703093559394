import { default as bijbelG276XTKUFiMeta } from "/vercel/path0/bible-assistant/pages/bijbel.vue?macro=true";
import { default as feedbackmHcH3RsfWFMeta } from "/vercel/path0/bible-assistant/pages/feedback.vue?macro=true";
import { default as indexbbHPdvO9QxMeta } from "/vercel/path0/bible-assistant/pages/index.vue?macro=true";
import { default as upload_45jsonz799UeCi4DMeta } from "/vercel/path0/bible-assistant/pages/upload-json.vue?macro=true";
import { default as indexgTTIW699nnMeta } from "/vercel/path0/bible-assistant/pages/upload/index.vue?macro=true";
import { default as pdf0SfooFC57QMeta } from "/vercel/path0/bible-assistant/pages/upload/pdf.vue?macro=true";
import { default as websitembDzoyudlWMeta } from "/vercel/path0/bible-assistant/pages/upload/website.vue?macro=true";
export default [
  {
    name: "bijbel___nl-NL",
    path: "/bijbel",
    meta: bijbelG276XTKUFiMeta || {},
    component: () => import("/vercel/path0/bible-assistant/pages/bijbel.vue").then(m => m.default || m)
  },
  {
    name: "bijbel___fi-FI",
    path: "/fi-FI/bijbel",
    meta: bijbelG276XTKUFiMeta || {},
    component: () => import("/vercel/path0/bible-assistant/pages/bijbel.vue").then(m => m.default || m)
  },
  {
    name: "bijbel___en-US",
    path: "/en-US/bijbel",
    meta: bijbelG276XTKUFiMeta || {},
    component: () => import("/vercel/path0/bible-assistant/pages/bijbel.vue").then(m => m.default || m)
  },
  {
    name: "feedback___nl-NL",
    path: "/feedback",
    component: () => import("/vercel/path0/bible-assistant/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: "feedback___fi-FI",
    path: "/fi-FI/feedback",
    component: () => import("/vercel/path0/bible-assistant/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: "feedback___en-US",
    path: "/en-US/feedback",
    component: () => import("/vercel/path0/bible-assistant/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: "index___nl-NL",
    path: "/",
    component: () => import("/vercel/path0/bible-assistant/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fi-FI",
    path: "/fi-FI",
    component: () => import("/vercel/path0/bible-assistant/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-US",
    path: "/en-US",
    component: () => import("/vercel/path0/bible-assistant/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "upload-json___nl-NL",
    path: "/upload-json",
    component: () => import("/vercel/path0/bible-assistant/pages/upload-json.vue").then(m => m.default || m)
  },
  {
    name: "upload-json___fi-FI",
    path: "/fi-FI/upload-json",
    component: () => import("/vercel/path0/bible-assistant/pages/upload-json.vue").then(m => m.default || m)
  },
  {
    name: "upload-json___en-US",
    path: "/en-US/upload-json",
    component: () => import("/vercel/path0/bible-assistant/pages/upload-json.vue").then(m => m.default || m)
  },
  {
    name: "upload___nl-NL",
    path: "/upload",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "upload___fi-FI",
    path: "/fi-FI/upload",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "upload___en-US",
    path: "/en-US/upload",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "upload-pdf___nl-NL",
    path: "/upload/pdf",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/pdf.vue").then(m => m.default || m)
  },
  {
    name: "upload-pdf___fi-FI",
    path: "/fi-FI/upload/pdf",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/pdf.vue").then(m => m.default || m)
  },
  {
    name: "upload-pdf___en-US",
    path: "/en-US/upload/pdf",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/pdf.vue").then(m => m.default || m)
  },
  {
    name: "upload-website___nl-NL",
    path: "/upload/website",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/website.vue").then(m => m.default || m)
  },
  {
    name: "upload-website___fi-FI",
    path: "/fi-FI/upload/website",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/website.vue").then(m => m.default || m)
  },
  {
    name: "upload-website___en-US",
    path: "/en-US/upload/website",
    component: () => import("/vercel/path0/bible-assistant/pages/upload/website.vue").then(m => m.default || m)
  }
]