
// @ts-nocheck


export const localeCodes =  [
  "nl-NL",
  "fi-FI",
  "en-US"
]

export const localeLoaders = {
  "nl-NL": [{ key: "../locales/nl-NL.json", load: () => import("../locales/nl-NL.json" /* webpackChunkName: "locale__vercel_path0_bible_assistant_locales_nl_NL_json" */), cache: true }],
  "fi-FI": [{ key: "../locales/fi-FI.json", load: () => import("../locales/fi-FI.json" /* webpackChunkName: "locale__vercel_path0_bible_assistant_locales_fi_FI_json" */), cache: true }],
  "en-US": [{ key: "../locales/en-US.json", load: () => import("../locales/en-US.json" /* webpackChunkName: "locale__vercel_path0_bible_assistant_locales_en_US_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "nl-NL",
      "iso": "nl-NL",
      "name": "Nederlands",
      "flag": "🇳🇱",
      "files": [
        "locales/nl-NL.json"
      ]
    },
    {
      "code": "fi-FI",
      "iso": "fi-FI",
      "name": "Finnish",
      "flag": "🇫🇮",
      "files": [
        "locales/fi-FI.json"
      ]
    },
    {
      "code": "en-US",
      "iso": "en-US",
      "name": "English",
      "flag": "🇺🇸",
      "files": [
        "locales/en-US.json"
      ]
    }
  ],
  "defaultLocale": "nl-NL",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl-NL",
    "iso": "nl-NL",
    "name": "Nederlands",
    "flag": "🇳🇱",
    "files": [
      {
        "path": "locales/nl-NL.json"
      }
    ]
  },
  {
    "code": "fi-FI",
    "iso": "fi-FI",
    "name": "Finnish",
    "flag": "🇫🇮",
    "files": [
      {
        "path": "locales/fi-FI.json"
      }
    ]
  },
  {
    "code": "en-US",
    "iso": "en-US",
    "name": "English",
    "flag": "🇺🇸",
    "files": [
      {
        "path": "locales/en-US.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
